import * as React from 'react';

import {
    FloatField, InputText, Message, Panel,
    classNames,
    useForm,
    useFormik,
    useLoading,
    useTranslation,
    validate,
    validations as V,
    ValidationBuilder,
    useToast,
} from '@components';
import { IUserIdentity } from '@models';
import { login } from '@store/actions/identity';
import './LoginPanel.scss';
import { IRecoverPasswordData } from '@models/forms';

export interface IProps {
    className?: string;
    style?: any;
    user?: IUserIdentity;
    setUser: Function;
    initializeApp: Function;
    recoverPassword: Function;
}

enum FormType {
    Login = 0,
    ResetPassword = 1
}

interface IResetFormData {
    email: string;
    userName: string;
}

export default function LoginPanel(props: IProps) {
    const [error, setError] = React.useState(undefined);
    const [formType, setFormType] = React.useState(FormType.Login);

    const { t } = useTranslation();
    const toast = useToast();
    const loading = useLoading();

    const [referrer, setReferrer] = React.useState<string | undefined>(undefined);

    const resetForm = useForm<IResetFormData>({
        initialValues: {
            email: '',
            userName: '',
        },
        validateOnMount: true,
        validate: ValidationBuilder.new().email('email').lift(),
    });

    const formik: any = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validate: (data) => {
            let errors: any = {};
            validate(data, 'username', errors, V.notEmpty, t('Name is required'));
            validate(data, 'password', errors, V.notEmpty, t('Password is required'));

            return errors;
        },
        onSubmit: loading.wrap(async (data: any) => {
            const resp = await login(data);
            if (resp.ok) {
                props.setUser(resp.value);
                setError(undefined);
                props.initializeApp();
                { document.getElementById('container')!.className = "" }
                { document.getElementById('root')!.className = "" }
            }
            else {
                setError(resp.message);
            }
        })
    });

    const isFormFieldValid = (name: string) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name: string) => {
        return isFormFieldValid(name) && <small className='p-error'>{formik.errors[name]}</small>;
    };

    const header = () => {
        return <div className='login-header r'>
            {t('Login')}
        </div>
    }

    const changePassword = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setError(undefined);

        setFormType(FormType.ResetPassword);
    }

    const cancel = () => {
        setFormType(FormType.Login);
        resetForm.setFieldValues({ email: '' });
    }

    const doReset = loading.wrap(async () => {
        const form: IRecoverPasswordData = {
            email: resetForm.values.email,
            userName: resetForm.values.userName,
        }
        const res = await props.recoverPassword(form);
        resetForm.setFieldValue('email', '');
        resetForm.setFieldValue('userName', '');
        if (res.isError) {
            toast.error(t(res.error));
        }
        else {
            toast.show(t('password.reset.send'));
        }
    });

    React.useEffect(() => {
        setReferrer(document.referrer);
    }, []);

    return <div className='login-content'>
        <Panel headerTemplate={header} className={props.className} style={props.style}>
            {toast.render()}
            {formType === FormType.ResetPassword && <div className='c he'>
                <div className='md pd-bottom title'>{t('password.reset.request')}</div>
                <div>
                    {resetForm.input('email', { placeholder: 'Email', type: 'email' })}
                </div>
                <div>
                    {resetForm.input('userName', { placeholder: t('Username'), type: 'text' })}
                </div>
                <span className='e'></span>
                <div className='r md pd-top'>
                    <button onClick={cancel}>{t('Cancel')}</button>

                    <span className='e'></span>

                    {loading.render()}
                    <button className='primary' onClick={doReset}
                        disabled={resetForm.values.email == '' || resetForm.values.userName == '' || resetForm.isInvalid()}>{t('Send')}</button>
                </div>
            </div>}

            {formType === FormType.Login && <>
                <form onSubmit={formik.handleSubmit} className='p-fluid c g-30'>
                    {/* <FloatField
                        id='username'
                        title={t('User')}
                        className='lg pd-top'
                        errorMessage={getFormErrorMessage('username')}
                        labelClassName={classNames({ 'p-error': isFormFieldValid('email') })}> */}
                    <InputText
                        id='username'
                        type='text'
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        autoFocus
                        className={classNames('inp', { 'p-invalid': isFormFieldValid('name') })}
                        placeholder={t('Username')} />
                    {/* </FloatField> */}
                    {/* <FloatField
                        id='password'
                        title={t('Password')}
                        errorMessage={getFormErrorMessage('password')}
                        labelClassName={classNames({ 'p-error': isFormFieldValid('password') })}> */}
                    <InputText
                        id='password'
                        type='password'
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        className={classNames('inp', { 'p-invalid': isFormFieldValid('password') })}
                        placeholder={t('Password')} />
                    {/* </FloatField> */}
                    <div className='log-footer'>
                        {formType === FormType.Login && !referrer?.includes('cae') &&
                            <a href='#' onClick={changePassword} className='link light'>{t('password.forget')}</a>}
                        <span className='e'></span>
                        {loading.render()}
                        {formType === FormType.Login &&
                            <span>
                                <button type='submit' className='primary' disabled={loading.isLoading()}>{t('Login')}</button>
                            </span>}
                    </div>
                </form>
            </>}
            {error && <Message severity={'error'} text={t(error!)} />}
        </Panel>
    </div>
}